var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{key:_vm.id_key,staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":this.$store.state.allocations,"item-key":"subgraphDeployment.ipfsHash","search":_vm.search,"custom-sort":_vm.customSort,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{
        'items-per-page-options': [5, 10, 15, 20, 25, 30, 40, 50, 100]
      },"items-per-page":_vm.allocations_per_page,"loading":this.loading,"loading-text":"Loading... Please wait","mobile-breakpoint":"0","show-select":this.selectable},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:itemsPerPage":function($event){_vm.allocations_per_page=$event},"update:items-per-page":[function($event){_vm.allocations_per_page=$event},_vm.updateAllocationsPerPage]},scopedSlots:_vm._u([(!_vm.selectable)?{key:"header",fn:function(ref){
      var headers = ref.props.headers;
return [_c('tr',{directives:[{name:"sortable",rawName:"v-sortable",value:({onEnd:_vm.updateHeaderOrder}),expression:"{onEnd:updateHeaderOrder}"}]},[_vm._l((headers),function(header){return [_c('th',{key:header.text},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-drag-horizontal ")])],1)]})],2)]}}:null,{key:"item.subgraphDeployment.versions[0].subgraph.image",fn:function(ref){
      var item = ref.item;
return [_c('v-badge',{attrs:{"value":item.subgraphDeployment.deniedAt,"bordered":"","color":"error","icon":"mdi-currency-usd-off","overlap":"","avatar":""}},[_c('v-avatar',{attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":item.subgraphDeployment.versions[0].subgraph.image}})],1)],1)]}},{key:"item.allocatedTokens",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(_vm.web3.utils.fromWei(item.allocatedTokens.toString())).format('0,0'))+" GRT ")]}},{key:"item.createdAt",fn:function(ref){
      var item = ref.item;
return [_c('span',{attrs:{"timestamp":item.createdAt}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,"MMM D, YYYY HH:mm"))+" ")])]}}],null,true)},[_c('span',[_vm._v("Epoch "+_vm._s(item.createdAtEpoch))])])],1)]}},{key:"item.activeDuration",fn:function(ref){
      var item = ref.item;
return [_c('span',{attrs:{"timestamp":item.activeDuration}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.epochDuration)+" epochs ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.readableDuration))])])],1)]}},{key:"item.subgraphDeployment.signalledTokens",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(_vm.web3.utils.fromWei(item.subgraphDeployment.signalledTokens.toString())).format('0,0'))+" GRT ")]}},{key:"item.subgraphDeployment.indexingRewardAmount",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(_vm.web3.utils.fromWei(item.subgraphDeployment.indexingRewardAmount.toString())).format('0,0'))+" GRT ")]}},{key:"item.subgraphDeployment.queryFeesAmount",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(_vm.web3.utils.fromWei(item.subgraphDeployment.queryFeesAmount.toString())).format('0,0'))+" GRT ")]}},{key:"item.subgraphDeployment.stakedTokens",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(_vm.web3.utils.fromWei(item.subgraphDeployment.stakedTokens.toString())).format('0,0'))+" GRT ")]}},{key:"item.proportion",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(item.proportion*100).format('0,0.000'))+"% ")]}},{key:"item.apr",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(item.apr).format('0,0.00'))+"% ")]}},{key:"item.newapr",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(item.newapr).format('0,0.00'))+"% ")]}},{key:"item.dailyrewards",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(_vm.web3.utils.fromWei(_vm.web3.utils.toBN(item.dailyrewards))).format('0,0'))+" GRT ")]}},{key:"item.dailyrewards_cut",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(_vm.web3.utils.fromWei(_vm.web3.utils.toBN(item.dailyrewards_cut))).format('0,0'))+" GRT ")]}},{key:"item.pending_rewards",fn:function(ref){
      var item = ref.item;
return [(item.pending_rewards === null && !_vm.automaticIndexingRewards)?_c('span',[_c('v-icon',{attrs:{"left":""},on:{"click":function($event){return _vm.getPendingAllocationRewards();}}},[_vm._v(" mdi-download ")])],1):_vm._e(),(item.pending_rewards === -1 || (_vm.automaticIndexingRewards && item.pending_rewards === null))?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"purple"}}):_vm._e(),(item.pending_rewards !== null && item.pending_rewards >= 0)?_c('span',[_vm._v(" "+_vm._s(_vm.numeral(_vm.web3.utils.fromWei(_vm.web3.utils.toBN(item.pending_rewards))).format('0,0'))+" GRT ")]):_vm._e(),(item.pending_rewards === -2)?_c('span',[_vm._v(" error ")]):_vm._e()]}},{key:"item.pending_rewards_cut",fn:function(ref){
      var item = ref.item;
return [(item.pending_rewards === null && !_vm.automaticIndexingRewards)?_c('span',[_c('v-icon',{attrs:{"left":""},on:{"click":function($event){return _vm.getPendingAllocationRewards();}}},[_vm._v(" mdi-download ")])],1):_vm._e(),(item.pending_rewards_cut === -1 || (_vm.automaticIndexingRewards && item.pending_rewards === null))?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"purple"}}):_vm._e(),(item.pending_rewards_cut !== null && item.pending_rewards_cut >= 0 && item.pending_rewards !== -2)?_c('span',[_vm._v(" "+_vm._s(_vm.numeral(_vm.web3.utils.fromWei(_vm.web3.utils.toBN(item.pending_rewards_cut))).format('0,0'))+" GRT ")]):_vm._e(),(item.pending_rewards === -2)?_c('span',[_vm._v(" error ")]):_vm._e()]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{staticStyle:{"font-size":"11px"}},[_c('strong',[_vm._v("Totals")])]),(_vm.selectable)?_c('td'):_vm._e(),_c('td',[_c('strong',[_vm._v(_vm._s(_vm.allocations.length)+" allocations")])]),_c('td'),_c('td'),_c('td'),_c('td',[_c('strong',[_vm._v(_vm._s(_vm.numeral(_vm.avgAPR).format('0,0.00'))+"%")])]),_c('td',[_c('strong',[_vm._v(_vm._s(_vm.numeral(_vm.web3.utils.fromWei(_vm.web3.utils.toBN(_vm.dailyrewards_sum))).format('0,0'))+" GRT")])]),_c('td',[_c('strong',[_vm._v(_vm._s(_vm.numeral(_vm.web3.utils.fromWei(_vm.web3.utils.toBN(_vm.dailyrewards_cut_sum))).format('0,0'))+" GRT")])]),_c('td',[_c('strong',[_vm._v(_vm._s(_vm.numeral(_vm.web3.utils.fromWei(_vm.web3.utils.toBN(_vm.pending_rewards_sum))).format('0,0'))+" GRT")])]),_c('td',[_c('strong',[_vm._v(_vm._s(_vm.numeral(_vm.web3.utils.fromWei(_vm.web3.utils.toBN(_vm.pending_rewards_cut_sum))).format('0,0'))+" GRT")])]),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td')])]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }