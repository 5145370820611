var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:this.indexingRewardCut},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.subgraphsToAllocate,"item-key":"currentVersion.subgraphDeployment.ipfsHash","custom-sort":_vm.customSort,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{
        'items-per-page-options': [10, 15, 20, 25, 30, 40, 50]
      },"items-per-page":_vm.subgraphs_per_page,"mobile-breakpoint":"0","show-expand":"","expanded":_vm.expandedItems},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
      var item = ref.item;
return [_c('v-badge',{attrs:{"value":item.currentVersion.subgraphDeployment.deniedAt,"bordered":"","color":"error","icon":"mdi-currency-usd-off","overlap":"","avatar":""}},[_c('v-avatar',{attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":item.image}})],1)],1)]}},{key:"item.currentVersion.subgraphDeployment.createdAt",fn:function(ref){
      var item = ref.item;
return [_c('span',{attrs:{"timestamp":item.currentVersion.subgraphDeployment.createdAt}},[_vm._v(_vm._s(_vm._f("moment")(item.currentVersion.subgraphDeployment.createdAt,"MMM D, YYYY HH:mm")))])]}},{key:"item.currentSignalledTokens",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(_vm.web3.utils.fromWei(item.currentSignalledTokens.toString())).format('0,0'))+" GRT ")]}},{key:"item.currentVersion.subgraphDeployment.indexingRewardAmount",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(_vm.web3.utils.fromWei(item.currentVersion.subgraphDeployment.indexingRewardAmount.toString())).format('0,0'))+" GRT ")]}},{key:"item.currentVersion.subgraphDeployment.queryFeesAmount",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(_vm.web3.utils.fromWei(item.currentVersion.subgraphDeployment.queryFeesAmount.toString())).format('0,0'))+" GRT ")]}},{key:"item.currentVersion.subgraphDeployment.stakedTokens",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(_vm.web3.utils.fromWei(item.currentVersion.subgraphDeployment.stakedTokens.toString())).format('0,0'))+" GRT ")]}},{key:"item.proportion",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(item.proportion*100).format('0,0.000'))+"% ")]}},{key:"item.apr",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(item.apr).format('0,0.00'))+"% ")]}},{key:"item.newapr",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(_vm.newAllocationCalcs[item.currentVersion.subgraphDeployment.ipfsHash].newapr).format('0,0.00'))+"% ")]}},{key:"item.dailyrewards",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(_vm.web3.utils.fromWei(_vm.web3.utils.toBN(_vm.newAllocationCalcs[item.currentVersion.subgraphDeployment.ipfsHash].dailyrewards))).format('0,0'))+" GRT ")]}},{key:"item.dailyrewards_cut",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numeral(_vm.web3.utils.fromWei(_vm.web3.utils.toBN(_vm.newAllocationCalcs[item.currentVersion.subgraphDeployment.ipfsHash].dailyrewards_cut))).format('0,0'))+" GRT ")]}},{key:"body.append",fn:function(){return undefined},proxy:true},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-slider',{key:_vm.refreshSlider,staticClass:"mt-4",staticStyle:{"max-width":"500px"},attrs:{"max":parseInt(_vm.web3.utils.fromWei(_vm.web3.utils.toBN(_vm.calculatedAvailableStake))) + (_vm.newAllocationSizes[item.currentVersion.subgraphDeployment.ipfsHash] ? _vm.newAllocationSizes[item.currentVersion.subgraphDeployment.ipfsHash] : 0),"min":"0"},on:{"change":_vm.updateAllocations},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"100px"},attrs:{"type":"number"},on:{"change":_vm.updateAllocations},model:{value:(_vm.newAllocationSizes[item.currentVersion.subgraphDeployment.ipfsHash]),callback:function ($$v) {_vm.$set(_vm.newAllocationSizes, item.currentVersion.subgraphDeployment.ipfsHash, $$v)},expression:"newAllocationSizes[item.currentVersion.subgraphDeployment.ipfsHash]"}})]},proxy:true}],null,true),model:{value:(_vm.newAllocationSizes[item.currentVersion.subgraphDeployment.ipfsHash]),callback:function ($$v) {_vm.$set(_vm.newAllocationSizes, item.currentVersion.subgraphDeployment.ipfsHash, $$v)},expression:"newAllocationSizes[item.currentVersion.subgraphDeployment.ipfsHash]"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }